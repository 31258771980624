import Glide from '@glidejs/glide';
import AutoHeight from '../lib/AutoHeight';

let sliders = document.querySelectorAll('[data-social-glide]');

if (sliders.length) {
    sliders.forEach(slider => {
        new Glide(slider, {
            type: 'carousel',
            perView: 4,
            breakpoints: {
                1400: {
                    perView: 3
                },
                1200: {
                    perView: 2
                },
                767: {
                    perView: 1
                }
            }
        }).mount({ AutoHeight });
    });
}