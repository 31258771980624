let load_items = document.querySelector('[data-load="portfolio"]');

if (load_items) {
    load_items.addEventListener('click', e => {
        e.preventDefault();
    
        let gallery = e.target.closest('[portfolio-gallery]');
        let next_items = gallery.querySelectorAll('.portfolio-gallery-hidden-media');
        next_items = Array.prototype.slice.call(next_items).slice(0, 11);
    
        next_items.forEach(item => {
            item.classList.remove('portfolio-gallery-hidden-media');
        });
    
        next_items = gallery.querySelectorAll('.portfolio-gallery-hidden-media');
    
        if (!next_items.length) {
            e.target.remove();
        }
    });
}