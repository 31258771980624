import imagesloaded from 'imagesloaded';

export default function (Glide, Components, Events) {
  const AUTOHEIGHT = {
    mount () {
      Components.Html.track.style.transition = 'height 0.6s cubic-bezier(0.165, 0.840, 0.440, 1.000)'
      AUTOHEIGHT.set()

      imagesloaded(Components.Html.track, () => {
        AUTOHEIGHT.set()
      })
    },

    set () {
      Components.Html.track.style.height = `${Components.Html.slides[Glide.index].offsetHeight}px`
    }
  }

  Events.on(['run', 'resize', 'run.after', 'build.after'], () => {
    AUTOHEIGHT.set()
  })

  return AUTOHEIGHT
}