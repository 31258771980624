let video_containers = document.querySelectorAll('[data-video-container]');

video_containers.forEach(container => {
    let paused = false;
    let play = container.querySelector('[data-video-control="play"]');
    let pause = container.querySelector('[data-video-control="pause"]');
    let track = container.querySelector('[data-video-control="track"]');
    let video = container.querySelector('video');

    play.addEventListener('click', e => {
        e.preventDefault();
        video.play();
    });

    pause.addEventListener('click', e => {
        e.preventDefault();
        video.pause();
    });

    track.addEventListener('click', e => {
        video.pause();
        let time = video.duration * (track.valueAsNumber * 0.001);
        video.currentTime = time;
        video.play();
    });

    video.addEventListener('play', e => {
        paused = false;
        container.classList.add('is-playing');
        window.requestAnimationFrame(() => { seekVideo(track, video) });
    });

    video.addEventListener('pause', e => {
        paused = true;
        container.classList.remove('is-playing');
    })

    window.requestAnimationFrame(() => { seekVideo(track, video) });

    function seekVideo(control, video) {
        if (!paused) {
            let time = (video.currentTime / video.duration) * 1000;
            control.value = time;
            window.requestAnimationFrame(() => { seekVideo(control, video) });
        }
    }
});