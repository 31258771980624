let pins = document.querySelectorAll('.location-state-pin');
let popups = document.querySelectorAll('.location-state-popup');

if (pins.length) {
    pins.forEach(pin => {
        pin.addEventListener('click', e => {
            e.preventDefault();
            e.stopPropagation();

            pins.forEach(other_pin => {
                if (other_pin === pin) {
                    return false;
                }

                other_pin.classList.remove('is-open');
            });

            pin.classList.toggle('is-open');
        });
    });

    popups.forEach(popup => {
        popup.addEventListener('click', e => {
            e.preventDefault();
            e.stopPropagation();
        });
    });
    
    document.body.addEventListener('click', (e) => {
        pins.forEach(pin => {
            pin.classList.remove('is-open');
        });
    });
}