let modal_toggles = document.querySelectorAll('[data-toggle="modal"]');

modal_toggles.forEach(toggle => {
    toggle.addEventListener('click', e => {
        e.preventDefault();

        let target = toggle.dataset.modalTarget ? document.querySelector(toggle.dataset.modalTarget) : toggle.closest('[data-modal]');

        if (target) {
            target.classList.toggle('is-open');

            let video = target.querySelector('video');

            if (video) {
                if (target.classList.contains('is-open')) {
                    if (video.dataset.src) {
                        video.src = video.dataset.src;
                        video.removeAttribute('data-src');
                    }
                    
                    video.play();
                } else {
                    video.pause();
                }
            }
        }
    });
});