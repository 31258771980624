class PageHeader {
    constructor(options) {
        this.state = {
            is_visible: true,
            is_open: false,
            last_scroll: window.pageYOffset
        }

        this.options = options;
        this.options.html = document.querySelector('html');
        this.options.body = document.querySelector('body');

        window.requestAnimationFrame(() => { this.checkScroll(); });
        this.options.toggle_el.addEventListener('click', () => { this.toggleMenu(); });

        this.options.menu_item_els.forEach((el) => {
            el.addEventListener('click', (e) => { this.toggleMenuItem(e); });
        });
    
        document.body.addEventListener('click', (e) => {
            this.options.el.classList.remove('search-is-open');
        });
        
        window.requestAnimationFrame(() => { this.setBodyPadding(); });
    }

    setBodyPadding() {
        if (this.options.el.offsetHeight !== parseInt(this.options.body.style.paddingTop)) {
            let header_height = this.options.el.offsetHeight;
            this.options.body.style.paddingTop = header_height + 'px';
            this.options.body.style.paddingBottom = 0;
        }
    
        window.requestAnimationFrame(() => { this.setBodyPadding(); });
    }

    checkScroll() {
        let scrolling_down = false;

        if (window.pageYOffset > 80 && window.pageYOffset > this.state.last_scroll) {
            scrolling_down = true;
        }

        if (window.pageYOffset > 80) {
            this.options.el.classList.add('is-scrolled');
        } else {
            this.options.el.classList.remove('is-scrolled');
        }

        if (window.pageYOffset !== this.state.last_scroll) {
            this.state.is_visible = !scrolling_down;
            this.state.last_scroll = window.pageYOffset;

            if (this.state.is_visible) {
                this.options.el.classList.add('is-visible');
                this.options.body.classList.add('has-visible-header');
            } else {
                this.options.el.classList.remove('is-visible');
                this.options.body.classList.remove('has-visible-header');
            }
        }

        window.requestAnimationFrame(() => { this.checkScroll(); });
    }

    toggleMenu() {
        this.state.is_open = !this.state.is_open;
        this.options.el.classList.toggle('is-open', this.state.is_open);
        this.options.html.classList.toggle('prevent-scroll', this.state.is_open);
    }

    toggleMenuItem(e) {
        if (window.innerWidth < 1040) {
            e.preventDefault();
            e.target.closest('.has-subnav').classList.toggle('is-open')
        }
    }
}

var page_header = new PageHeader({
    el: document.querySelector('[page-header]'),
    toggle_el: document.querySelector('[page-header-hamburger]'),
    menu_item_els: document.querySelectorAll('[page-header-menu-item]')
});

var dropdownLinks = document.querySelectorAll('.has-subnav');

for(var i = 0; i < dropdownLinks.length; i++){
    var currentHTML = dropdownLinks[i].innerHTML
    dropdownLinks[i].innerHTML = `${currentHTML} <span class="dropdown-toggle"></span>`;
}

let innerDropdownButtons = document.querySelectorAll('.dropdown-toggle');

for(let i = 0; i < innerDropdownButtons.length; i++){
    
    (function(index){
        innerDropdownButtons[index].addEventListener('click', () => {
            // console.log(index)
            // console.log(innerDropdownButtons[index])
            // console.log(window.getComputedStyle( innerDropdownButtons[index].parentElement.querySelector(".page-header-subnav"), null).display )
            let elDisplay = window.getComputedStyle( innerDropdownButtons[index].parentElement.querySelector(".page-header-subnav"), null).display
            if(elDisplay === "none"){
                innerDropdownButtons[index].parentElement.querySelector(".page-header-subnav").style.display = "block";
            }else {
                innerDropdownButtons[index].parentElement.querySelector(".page-header-subnav").style.display = "none";
            }
        })
    })(i)
}
