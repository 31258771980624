import Glide from '@glidejs/glide';

let sliders = document.querySelectorAll('[data-glide]');

if (sliders.length) {
    sliders.forEach(slider => {
        new Glide(slider, {
            type: 'carousel'
        }).mount();
    });
}