import Glide from '@glidejs/glide';

let sliders = document.querySelectorAll('[data-image-glide]');

if (sliders.length) {
    sliders.forEach(slider => {
        new Glide(slider, {
            type: 'carousel',
            perView: 3,
            breakpoints: {
                767: {
                    perView: 2
                }
            }
        }).mount();
    });
}